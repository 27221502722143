<script setup>
import { /* useRouter,  */useRoute } from 'vue-router'
import { ref, defineAsyncComponent, nextTick } from 'vue'
import { getResourceIdAndNameFromResourceIdParam } from '@/utils/id'
import VConsole from 'vconsole';
import { getEnvironment } from "@/utils/env";

const { ENV, isDev } = getEnvironment();


const $route = useRoute()

const { resourceBase, resourceId, resourceName }
= getResourceIdAndNameFromResourceIdParam($route.params.resource_id);

console.log($route, resourceBase, resourceId, resourceName)
// const filename = $route.params.resource_id.length > 1 ? "_base" : `sub/${resourceName}`

// console.log({ resourceBase, resourceId, resourceName, filename })

const AsyncComp = defineAsyncComponent({
    loader: () => {
        if ($route.params.resource_id.length > 1) {
            return import(`./${resourceBase}/_base.vue`)
        } else {
            return import(`./${resourceBase}/sub/${resourceName}.vue`)
        }  
    },
    inheritAttrs: false
});

function getSearchParams() {
    const searchParams = new URLSearchParams(window.location.search);

    return {
        "debug": searchParams.get("debug")
    }
}

const params = getSearchParams()

const DEBUGABLE = params.debug == "1234"

const instances = {
    vConsole: DEBUGABLE ? new VConsole({ theme: 'dark' }) : null
}

console.log("LIFF Base Loaded");

const isDebug = ref((DEBUGABLE && ENV !== "production") || false);
const isLoading = ref(true);

function toggleDebugger() {
    if (DEBUGABLE) {
        isDebug.value = !isDebug.value;

        const vConsoleElem = document.getElementById('__vconsole');
        vConsoleElem.style.opacity = isDebug.value === false ? 0 : 1;

        nextTick();
    }
}

function toggleLoading() {
    isLoading.value = !isLoading.value;
}

</script>

<template>
    <ProgressSpinner
        class="spinner"
        :class="isLoading ? 'loader-active' : 'loader-inactive'"
        strokeWidth="2" 
        animationDuration=".3s"
        aria-label="Loading"
    />

    <div class="liff-base">
        <template v-if="resourceId">
            <AsyncComp
                v-bind="{
                    isDebug,
                    resourceId,
                    resourceName,
                    isLoading,
                    toggleLoading
                }"
            />
        </template>
        <template v-else>
            <div>
                Error loading LIFF app. Invalid ID
            </div>
        </template>
    </div>
    <div v-if="DEBUGABLE" class="debugger-bar">
        <button
            @click="toggleDebugger"
            class="debugger-toggle-button"
        >
            &#129520;
        </button>
    </div>
    <!-- <div style="color: red">debugging on Liff base {{ isDebug ? "on" : "off" }}</div> -->
</template>

<style>
#__vconsole {
    opacity: 0
}

.spinner {
    position: absolute;
    z-index: 9999999;
    width: 30vw;
    height: 30vh;
    max-width: 300px;
    max-height: 300px;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: all ease 2s;
}

.loader-active {
    opacity: 1;
    z-index: 9999999;
}

.loader-inactive {
    opacity: 0;
    z-index: 0;
}
.debugger-toggle-button {
    position: absolute;
    bottom: 0;
    left: 0;
    background: none;
    border: none;
}
.debugger-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
}
</style>