interface StringToStringMap {
    [key: string]: string
}

export const resourceIdRegistry: StringToStringMap = {
    "_adbplqwz-tbcl9ov3lc0l31ej": "sansiri/dcondo",
    "_adbplqwz-ylp2zetawcbnp0m7": "sansiri/the-muve",
    "_adbplqwz-q1oj5kn74h32csiq": "sansiri/the-base",
}

export const resourceNameIdMap: StringToStringMap = {
    "dcondo": "adbplqwz-tbcl9ov3lc0l31ej",
    "the-muve": "adbplqwz-ylp2zetawcbnp0m7",
    "the-base": "adbplqwz-q1oj5kn74h32csiq",
    "sansiri/dcondo": "adbplqwz-tbcl9ov3lc0l31ej",
    "sansiri/the-muve": "adbplqwz-ylp2zetawcbnp0m7",
    "sansiri/the-base": "adbplqwz-q1oj5kn74h32csiq",
}
  
export function getResourceIdAndNameFromResourceIdParam(
    resource_id: string[]
) {
    const fullResourceId = resource_id.length > 1 
        ? resource_id.join("/")
        : resource_id[0]
    console.log("ID", fullResourceId);

    let resourceBase, resourceId, resourceName = null

    // console.log([...resource_id], [...resource_id].splice(-1))
    function getAllAndLast(arr: any[]) {
        const last = [...arr].pop();
        const all = [...arr].reverse()
            .slice(1)
            .reverse()

        return [all, last]
    }

    if (resource_id.length === 1) {
        if (fullResourceId[0] === "_") {
            const _ = resourceIdRegistry[fullResourceId].split("/")
            const [all, last] = getAllAndLast(_)
            
            resourceId = fullResourceId.split("_")[1]
            resourceName = last
            resourceBase = all.join("/")

        } else {
            resourceId = resourceNameIdMap[resource_id[0]]
            
            const _ = resourceIdRegistry["_" + resourceId].split("/")
            const [all, last] = getAllAndLast(_)

            resourceBase = all.join("/")
            resourceName = last
        }
    } else if (resource_id.length > 1) {
        const [all, last] = getAllAndLast(resource_id)
        
        resourceId = resourceNameIdMap[resource_id.join("/")]
        resourceBase = all.join("/")
        resourceName = last
    }
  
    return { resourceBase, resourceId, resourceName }
}
